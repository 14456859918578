import logo from './logo.svg';
import './page.css';

function App() {
  return (
    <div className="container">
        <img src={logo} className="panda" alt="Panda" />
        <h1 className="title">
          Take a Quick Survey
        </h1>
        <p className="intro">
          Choose from one of the links below to share your feedback with our team.
        </p>
        <div className="buttons">
        {<a
          className="button"
          href="https://t.maze.co/254880774?guerilla=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          General Feedback
        </a>}
        
        {<a
          className="button"
          href="https://t.maze.co/246349694?guerilla=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          AI Enhancements
        </a>}
        
        {<a
          className="button"
          href="https://t.maze.co/255214540?guerilla=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Moderate Assessments
        </a>}
        
        {<a
          className="button"
          href="https://t.maze.co/253738629?guerilla=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Quiz Features
        </a>}
        </div>
      </div>
  );
}

export default App;
